import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import ReactHTMLParser from "react-html-parser";
import { colors, breakpoints, fonts } from "../style-utilities/variables";
import ButtonGrey from "./ButtonGrey";

const Div = styled.div`
  margin-top: 4em;
  display: flex;
  max-width: 1000px;
  margin: 0 auto;
  flex-direction: column;

  .gray-circle,
  .black-circle {
    position: relative;
    align-self: center;
    justify-content: center;
  }
  .gray-circle {
    background-color: ${colors.secondaryGray};
    color: ${colors.black};
  }
  .black-circle {
    background-color: ${colors.black};
    color: ${colors.white};
    h2 {
      color: ${colors.white};
    }
  }
  .margin-bottom {
    margin-bottom: 4em;
  }

  @media (min-width: 500px) {
    .gray-circle {
      align-self: flex-start;
      left: -40px;
    }
    .black-circle {
      align-self: flex-end;
    }
  }

`;

const StyledBlock = styled.div`
  border-radius: 50%;
  width: 130vw;
  height: 130vw;
  background-color: #000000;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 2em;
  h2,
  p {
    max-width: 300px;
  }
  p {
    font-size: 26px;
  }
  @media (min-width: 400px) {
    width: 550px;
    height: 550px;
    h2,
    p {
      max-width: 500px;
    }
  }
`;

const InfoBubbles = () => {
  return (
    <Div>
      <StyledBlock className="black-circle margin-bottom">
      <h2>We&rsquo;re Always Thinking</h2>
        <p>Read the latest insights from our marketing whizzes.</p>
        <ButtonGrey
          textLabel="See Insights"
          pageLink="/insights"
          className="whiteCTA"
        />
      </StyledBlock>
      <StyledBlock className="gray-circle">
        <h2>We&rsquo;ve Been Busy</h2>
        <p>Get current on company news.</p>
        <ButtonGrey
          textLabel="See News"
          externalLink="/about/news"
          className="whiteCTA"
        />
      </StyledBlock>
    </Div>
  );
};

export default InfoBubbles;
