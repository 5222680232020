import React, { Component } from "react";
import styled from "styled-components";
import { colors, fonts, breakpoints } from "../../../style-utilities/variables";
import Bio from "./BioTab";
import BioNav from "./BioNav";

const Div = styled.div`
  padding-top: 4em;
  position: relative;
  .slider-section {
    margin-top: 4em;
  }
  .slider-section.board {
    position: relative;
  }
  .slider-section.board.show-tab {
    height: 100vh;
  }
  .slider-container {
    .hide {
      transform: translate3d(100vw, 0, 0);
      transition: transform 0.5s cubic-bezier(0, 0, 0, 1);
      height: 0;
      .circle {
        display: none;
      }
    }
    .show {
      transform: translate3d(0, 0, 0);
      transition: transform 0.5s cubic-bezier(0, 0, 0, 1);
      height: 100%;
      padding: 4em 1em;
    }
    @media (min-width: ${breakpoints.mobileMax}) {
      display: flex;
      position: static;
      margin: 3em 0 10em;
      .show {
        padding: 0;
      }
      .hide {
        height: auto;
      }
      .hide,
      .show {
        transform: none !important;
        transition: none !important;
      }
    }
    .off .circle {
      z-index: 0;
    }
  }
  @media (min-width: ${breakpoints.mobileMax}) {
    .slider-section.board.show-tab {
      height: auto;
    }
  }
`;

class BioSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leaderTabs: this.props.data[0].bio_slider,
      boardTabs: this.props.data[1].bio_slider,
      activeLeader: 0,
      activeBoard: 0,
      visibleLead: false
    };
  }

  highlightLeader = idx => {
    this.setState({
      activeLeader: idx,
      visibleLead: !this.state.visibleLead
    });
  };

  highlightBoard = idx => {
    this.setState({
      activeBoard: idx,
      visibleBoard: !this.state.visibleBoard
    });
  };

  closeLead = () => {
    this.setState({
      visibleLead: !this.state.visibleLead
    });
  };

  closeBoard = () => {
    this.setState({
      visibleBoard: !this.state.visibleBoard
    });
  };

  render() {
    const {
      leaderTabs,
      boardTabs,
      activeLeader,
      activeBoard,
      visibleLead,
      visibleBoard
    } = this.state;

    return (
      <Div>
        <div className="slider-section" id="leaders">
          <h2 className="page-container">
            {this.props.data[0].slider_headline}
          </h2>
          <div className="slider-container">
            <BioNav
              data={this.props.data[0].bio_slider}
              activeIndex={activeLeader}
              visible={visibleLead}
              highlightProfile={this.highlightLeader}
            />
            <Bio
              tab={leaderTabs[activeLeader]}
              activeIndex={activeLeader}
              type={this.props.type}
              visible={visibleLead}
              data={this.props.data[0]}
              closeTab={this.closeLead}
              imageData={this.props.imageData}
            />
          </div>
        </div>
        <div
          className={
            visibleBoard
              ? "slider-section board show-tab"
              : "slider-section board"
          }
        >
          <h2 className="page-container">
            {this.props.data[1].slider_headline}
          </h2>
          <div className="slider-container">
            <BioNav
              data={this.props.data[1].bio_slider}
              highlightProfile={this.highlightBoard}
              activeIndex={activeBoard}
              visible={visibleBoard}
            />
            <Bio
              data={this.props.data[1]}
              visible={visibleBoard}
              type={this.props.type}
              closeTab={this.closeBoard}
              activeIndex={activeBoard}
              tab={boardTabs[activeBoard]}
              imageData={this.props.imageData}
            />
          </div>
        </div>
      </Div>
    );
  }
}

export default BioSlider;
