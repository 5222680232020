import React, { Component } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { colors, breakpoints, fonts } from '../style-utilities/variables';
import ReactHtmlParser from 'react-html-parser';

//COMPONENTS
import InfoBubblesLeadership from '../components/InfoBubblesLeadership';
import ButtonGrey from '../components/ButtonGrey';
import TabSlider from '../components/TabSliders/BioSlider/BioSlider';
import Hero from '../components/Hero';
import ContactBanner from '../components/ContactBanner';
import NewsSection from '../components/News/NewsSection';
import SEO from '../components/SEO';
import ImageCircle from '../components/ImageCircle';
import DefaultSharingImage from './../static/ansira-logo-sharing.png';

const StyledLeadership = styled.main`
  margin: 0 auto;
  max-width: 1500px;
  overflow-x: hidden;
  @media (min-width: 1500px) {
    overflow-x: visible;
  }
  .max-width {
    max-width: 1500px;
    margin: 0 auto;
  }
  .intro-copy {
    width: 100%;
  }
  .row {
    margin-right: 0;
    margin-left: 0;
  }
  .col-md-7 {
    padding-right: 0;
    padding-left: 0;
  }

  .award {
    max-width: 220px;
    margin: 0 auto;
    text-align: center;
    a {
      color: rgba(0, 0, 0, 0.8);
    }
    .img-cont {
      max-width: 200px;
      margin: 0 auto;
      padding: 0 10px;
      img {
        min-width: 140px;
        width: 100%;
      }
    }
  }
  .center {
    margin-top: 5rem;
    text-align: center;
  }
  h2.center {
    font-size: 48px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    .intro-copy {
      width: 100%;
    }
    .right {
      width: 45%;
      padding-top: 0;
      height: auto;
    }
    .awards-container {
      display: flex;
      flex-wrap: wrap;
    }
    h2.center {
      margin-bottom: 2em;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    .bullet-section {
      padding: 8em 0;
    }
    .intro-copy {
      width: 70%;
    }
  }

  .sub-section {
    p {
      font-weight: 400;
      color: ${colors.primaryGray};
    }

    .content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 2rem;

      h2 {
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 0.8rem;
        @media (min-width: ${breakpoints.tablet}) {
          font-size: 28px;
        }
      }
    }

    .img-section {
      padding: 0px 0 40px;
    }
    .left {
      order: 1;
    }
    .right {
      order: 2;
    }
    .body-copy {
      font-size: 22px;
      width: 100%;
    }

    @media (max-width: ${breakpoints.mobileMax}) {
      .img-section {
        width: 120vw;
        margin: 0 calc(-150px + 20vw);
      }
    }

    @media (min-width: ${breakpoints.tablet}) {
      position: relative;
      .img-section {
        padding: 0;
        &.left {
          right: 190px;
        }
        &.right {
          left: 80px;
        }
      }
    }

    @media (min-width: ${breakpoints.max}) {
      .img-section {
        &.left {
          right: 140px;
        }
        &.right {
          left: 110px;
        }
      }
    }
  }
`;

class Leadership extends Component {
  state = {
    isPreviewMode: false,
    previewPayload: null
  };

  componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    /*
    Preview Mode -------------------------------------
    */
    if (params.get('preview')) {
      let id = params.get('id');
      let wpnonce = params.get('_wpnonce');
      let BASE_URL = `https://api.dev.ansira.io/wp-json/wp/v2`;

      fetch(`${BASE_URL}/pages/${id}/revisions/?_wpnonce=${wpnonce}`, {
        mode: 'cors',
        credentials: 'include'
      })
        .then(response => {
          return response.json();
        })
        .then(myJson => {
          if (myJson) {
            this.setState({
              isPreviewMode: true,
              previewPayload: myJson[0]
            });
          }
        });
    }
    /*
    -------------------------------- -----------------
    */
  }

  render() {
    let leadership_page = this.props.data.wordpressPage;

    if (this.state.isPreviewMode) {
      leadership_page.acf = this.state.previewPayload.acf;
    }

    const LeaderBoardData = this.props.data.allWordpressWpOurBoard.edges.concat(
      this.props.data.allWordpressWpOurLeadership.edges
    );

    return (
      <StyledLeadership className="animate">
        <SEO
          isBlogPost={false}
          postData={leadership_page}
          postImage={DefaultSharingImage}
        />
        <Hero
          headline={leadership_page.acf.hero_headline}
          sub={leadership_page.acf.hero_sub_headline}
        />
          <div className="intro-copy page-container">
            {ReactHtmlParser(leadership_page.acf.intro_copy)}
          </div>
          <TabSlider
            data={leadership_page.acf.create_slider}
            imageData={LeaderBoardData}
            bioSlider={true}
          />
          <InfoBubblesLeadership />
          <div className="leadership-container">
            <ContactBanner
              headline={leadership_page.acf.contact_headline}
              cta={leadership_page.acf.contact_cta}
              url={leadership_page.acf.contact_url}
            />
          </div>
      </StyledLeadership>
    );
  }
}

export const query = graphql`
  query {
    allWordpressWpOurBoard {
      edges {
        node {
          wordpress_id
          better_featured_image {
            source_url
          }
        }
      }
    }
    allWordpressWpOurLeadership {
      edges {
        node {
          wordpress_id
          better_featured_image {
            source_url
          }
        }
      }
    }
    wordpressPage(slug: { eq: "leadership" }) {
      content
      slug
      title
      type
      content
      acf {
        seo_title
        seo_canonical
        seo_description
        hero_headline
        hero_sub_headline
        intro_copy
        content_blocks {
          content
          heading
          image {
            source_url
          }
        }
        slider_type
        create_slider {
          slider_headline
          bio_slider {
            post_title
            post_content
            wordpress_id
            acf {
              title
              first_name
              last_name
            }
          }
        }
        contact_headline
        contact_cta
        contact_url
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
  }
`;

export default Leadership;
