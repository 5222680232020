import React from "react";
import styled, { css } from "styled-components";
import Arrow from "../../Arrow";
//vars
import { colors, breakpoints } from "../../../style-utilities/variables";

//function to handle the red line under the highlighted image:
function redOnActive() {
  let styles = "";
  for (let i = 0; i < 20; i++) {
    styles += `.styledSliderWrapper.active-thumbnail-${i} #tab-${i} {
          p {
            color:${colors.primary};
          }
          .circle {
            display: block;
          }
        }
    `;
  }
  return css`
    ${styles}
  `;
}

const LeftNav = styled.div`
  padding-right: 20px;

  .label-container {
    color: #757575;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    position: relative;
    p {
      margin-bottom: 1rem;
      font-weight: bold;
      height: 50px;
      padding-right: 60px;
      color: #757575;
      font-size: 19px;
      text-align: right;
      :hover {
        cursor: pointer;
      }
    }
    .circle {
      display: none;
      top: -4px;
      position: absolute;
      min-width: 36px;
      min-height: 36px;
      z-index: 2;
      border-radius: 50%;
      background: white;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
      -o-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
      .arrow svg {
        position: absolute;
        top: 10px;
        left: 10px;
      }
    }
  }
  @media(min-width: ${breakpoints.mobileMax}) {
    width: 30%;
    .label-container p {
      margin-bottom: 1.5rem;
    }
    
  }
  @media(min-width: ${breakpoints.desktop}) {
    .label-container p {
      font-size: 20px;
    }
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .styledSliderWrapper {
      justify-content: space-around;
    }
  }

  ${redOnActive()};
`;
const Thumbnail = props => {
  const data = props.data;
  return (
    <LeftNav>
      <div
        className={`styledSliderWrapper active-thumbnail-${props.activeIndex}`}
        id={props.id}
      >
        {data.map((navName, idx) => {
          return (
            <div
              className="label-container"
              id={`tab-${idx}`}
              onClick={() => props.highlightProfile(idx)}
            >
              <p>{navName.post_title}</p>
              <div className="circle">
                <Arrow color="rgb(236, 55, 37)" />
              </div>
            </div>
          );
        })}
      </div>
    </LeftNav>
  );
};
export default Thumbnail;
