import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import Arrow from '../../Arrow';
//vars
import { colors, breakpoints, fonts } from '../../../style-utilities/variables';

const StyledTabSlide = styled.div`
  padding: 0;
  position: absolute;
  z-index: 4;
  width: 100vw;
  padding: 2em 2em 5em 3.3em;
  background: white;
  top: 0;
  .copy-img-container {
    display: block;

    .profile-image {
      width: 70%;
    }
    .profile-copy {
      width: 100%;
    }
  }
  .large-name {
    margin-bottom: 0;
    margin-bottom: 0.1em;
    color: ${colors.lightGray};
  }

  .circle {
    width: 36px;
    height: 36px;
    background: white;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 1em;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    -o-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    .arrow svg {
      margin-top: 10px;
      margin-left: 10px;
      transform: rotate(0);
    }
  }

  @media (min-width: ${breakpoints.mobileMax}) {
    position: static;
    width: 65%;
    margin-left: 40px;
    padding: 0 0 0 0;
    .copy-img-container {
      display: flex;
      .profile-image {
        width: 30%;
        margin-right: 2%;
      }
      .profile-copy {
        width: 68%;
      }
    }
    .large-name {
      font-size: 72px;
    }
    .circle {
      display: none;
    }
  }
  @media (min-width: ${breakpoints.desktop}) {
    .large-name {
      font-size: 48px;
    }
    .copy-img-container {
      .profile-copy {
        width: 65%;
      }
    }
  }
`;

const Featured = props => {
  let visibility = 'hide';
  if (props.visible) {
    visibility = 'show';
  }

  return (
    <StyledTabSlide type={props.type} className={`${visibility}`}>
      <div className="circle" onClick={() => props.closeTab()}>
        <Arrow color="rgb(236, 55, 37)" />
      </div>
      <h2 className="large-name">{props.tab.post_title}</h2>
      <h4>{props.tab.acf.title}</h4>
      <div className="copy-img-container">
        {props.imageData.map((wordpressData, i) => {
          if (props.tab.wordpress_id == wordpressData.node.wordpress_id) {
            return (
              <div className="profile-image">
                <img
                  src={wordpressData.node.better_featured_image.source_url}
                  alt={props.tab.post_title}
                />
              </div>
            );
          }
        })}
        <div className="profile-copy">
          {ReactHtmlParser(props.tab.post_content)}
        </div>
      </div>
    </StyledTabSlide>
  );
};
export default Featured;
